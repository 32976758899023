<template>
  <div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>